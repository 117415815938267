<template>
  <div>
    <div v-if="user_id != null">
      <template v-if="!loading">
        <div class="py-2 px-4 border-bottom d-none d-lg-block">
          <div class="d-flex align-items-center py-1">
            <div class="position-relative">
              <img
                :src="this.user.avatar"
                class="rounded-circle mr-1"
                :alt="user.name"
                width="40"
                height="40"
              />
            </div>
            <div class="flex-grow-1 pl-3">
              <strong>{{ user.name }}</strong>
              <div class="text-muted small">
                Last Seen: {{ user.last_seen }}
              </div>
            </div>
          </div>
        </div>

        <div class="position-relative">
          <div class="chat-messages p-4" id="chat-body-content">
            <div
              :class="`chat-message-${message.sent ? 'right' : 'left'} pb-4`"
              v-for="(message, i) in messages"
              :key="`message-${i}`"
            >
              <div class="flex-shrink-1 bg-light rounded py-2 px-3">
                <div class="font-weight-bold mb-1">{{ message.user }}</div>
                {{ message.content }}
                <div class="class-muted text-end">
                  <small>{{ message.date }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow-0 py-3 px-4 border-top">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Type your message"
              v-model="new_message"
            />
            <button class="btn btn-primary" @click.prevent="submit">Send</button>
          </div>
        </div>
      </template>
      <loading v-if="loading" />
    </div>
    <div v-if="user_id == null">
      <div class="py-2 px-4 border-bottom d-none d-lg-block">
        <div class="d-flex align-items-center py-1">
          <div class="flex-grow-1 pl-3">
            <strong>Select Chat</strong>
            <div class="text-muted small">.</div>
          </div>
        </div>
      </div>

      <div class="position-relative">
        <div class="chat-messages p-4">
          <p>Select chat to view.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if(this.user_id != null) {
      this.fetch();
    }
  },

  data() {
    return {
      loading: true,
      messages: [],
      user: {},
      new_message: null,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get(`/api/v1/messages/${this.user_id}`).then((response) => {
        this.loading = false;
        this.messages = response.data.messages;
        this.user = response.data.user;
        this.listenPusher()
        this.scrollToBottom()
      });
    },

    submit() {
      let data = {
        content: this.new_message,
        to: this.user_id,
      };

      this.$axios.post("/api/v1/messages", data).then((response) => {
        this.messages.push(response.data.message);
        this.new_message = null;
      });
    },

    listenPusher() {
      this.$echo.private(`messages.${this.my_id}`)
        .listen('MessageSent', message => {
          this.messages.push(message)
          this.scrollToBottom()
        })
    },

    scrollToBottom() {
      setTimeout(() => {
        var div = document.getElementById('chat-body-content')
        div.scrollTop = div.scrollHeight - div.clientHeight
      }, 100)
    }
  },

  watch: {
    user_id() {
      this.fetch();
    },
  },

  computed: {
    user_id() {
      return this.$route.query.id;
    },

    my_id() {
      return this.$store.getters.getAuthUser.id
    }
  },

  beforeDestroy() {
    this.$echo.leaveChannel(`messages.${this.my_id}`);
  }
};
</script>
